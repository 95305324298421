<template>
  <div class="mb-3">
    <MainSlider></MainSlider>
  </div>
  <div class="container-fluid">
    <div class="card-header-home">MOST POPULAR</div>
    <MostPopular></MostPopular>
  </div>

  <div class="container-fluid">
    <div class="row mt-2 mb-1">
      <div class="col-sm-4">
        <div class="card card-home">
          <div class="intro-container">
            <div class="intro-text-container">
              <div class="intro-header">MANUFACTURER</div>
              <div class="intro-text">
                The Largest Food Processing, Industrial And Agri-based Machine
                Manufacturer In SRI LANKA
              </div>
            </div>
            <div>
              <img
                class="img-fluid img-card"
                style="width: 100%"
                src="@/assets/images/home_page/divisions/manufacturing.jpg"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="card card-home">
          <div class="intro-container">
            <div class="intro-text-container">
              <div class="intro-header">FOUNDRYMEN</div>
              <div class="intro-text">
                Leading Foundrymen In The SRI LANKA And The Lagest Foundry
                Contractor For Govenment And Private Sector
              </div>
            </div>
            <div>
              <img
                class="img-fluid img-card"
                style="width: 100%"
                src="@/assets/images/home_page/divisions/foundry.jpg"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="card card-home">
          <div class="intro-container">
            <div class="intro-text-container">
              <div class="intro-header">EXPORTER</div>
              <div class="intro-text">
                Proud Exporter Under MADE IN SRI LANKA. We Export Agro And Food
                Machine To Out World Wide Customers
              </div>
            </div>
            <div>
              <img
                class="img-fluid img-card"
                style="width: 100%"
                src="@/assets/images/home_page/divisions/export.jpg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row mb-3">
      <div class="col-lg-4">
        <div style="position: relative" class="industry-container card-home">
          <div style="position: absolute; width: 100%">
            <div class="header-sub-1">UDAYA COCO MACHINE SOLUTIONS</div>
            <div class="industry-desc">
              All In One Package to Virgin Coconut Oil and Ordanary Coconut Oil
              Industry
            </div>
          </div>

          <img
            class="img-fluid img-card"
            src="@/assets/images/home_page/industry/industry_coconut.jpg"
          />

          <div class="industry-link-container">
            <div class="industry-link-bg"></div>
            <router-link class="industry-link" :to="'/cocomachines'">
              Learn More...
            </router-link>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div style="position: relative" class="industry-container card-home">
          <div style="position: absolute; width: 100%">
            <div class="header-sub-1">UDAYA SPICE PROCESSING SOLUTIONS</div>
            <div class="industry-desc">UDAYA Spice Processing Machines</div>
          </div>

          <img
            class="img-fluid img-card"
            src="@/assets/images/home_page/industry/industry_spice.jpg"
          />
          <div class="industry-link-container">
            <div class="industry-link-bg"></div>
            <router-link class="industry-link" :to="'/products?c=2'">
              Learn More...
            </router-link>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div style="position: relative" class="industry-container card-home">
          <div style="position: absolute; width: 100%">
            <div class="header-sub-1">UDAYA PEPPER PROCESSING SOLUTIONS</div>
            <div class="industry-desc">
              UDAYA Pepper Industry Related Machines
            </div>
          </div>

          <img
            class="img-fluid img-card"
            src="@/assets/images/home_page/industry/industry_pepper.jpg"
          />
          <div class="industry-link-container">
            <div class="industry-link-bg"></div>
            <router-link class="industry-link" :to="'/products?k=pepper'">
              Learn More...
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="card-header-home-2">TRUSTED CLIENTS</div>

    <div class="row mb-3">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div
          style="display: flex"
          class="clients-container"
          @click="gotoClients"
        >
          <div
            v-for="(irow, index) in clients"
            :key="index"
            style="animation: fadeIn 1s; width: 10%; padding: 1px"
          >
            <div class="card mb-1">
              <div>
                <div style="position: relative">
                  <div>
                    <img
                      class="img-fluid"
                      style="animation: fadeIn 1s"
                      :src="irow.logo"
                    />
                  </div>
                  <div class="flag">
                    <img
                      class="img-fluid"
                      style="animation: fadeIn 1s"
                      :src="irow.flag"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import MainSlider from "@/components/Layout/PublicWeb/MainSlider.vue";
import MostPopular from "@/components/Layout/PublicWeb/MostPopular.vue";
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    MainSlider,
    MostPopular,
  },
  data() {
    return {
      products_popular: [],
      clients: [],
    };
  },
  beforeMount() {
    this.getProductsPopular();
    this.getClientsRows();
  },
  methods: {
    async getProductsPopular() {
      try {
        const response = await axios.get("get_product_popular_home");
        this.products_popular = response.data;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async getClientsRows() {
      try {
        const response = await axios.get("/get_clients_home");

        this.clients = response.data;
      } catch (error) {
        return false;
      }
    },
    gotoClients() {
      this.$router.push("/clients");
    },
  },
};
</script>

<style scoped>
.intro-container {
  height: 100%;
  background: rgb(255, 255, 255, 0.85);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.85) 40%,
    rgba(255, 255, 255, 0) 100%
  );
}
.intro-text-container {
  top: 20%;
  position: absolute;
}
.intro-header {
  color: #fff;
  font-size: 1.5em;
  padding: 10px 10px 0px 10px;
  background: rgb(15, 3, 43);
  background: linear-gradient(
    90deg,
    rgba(15, 3, 43, 1) 20%,
    rgba(15, 3, 43, 0) 100%
  );
  width: 100%;
}
.intro-text {
  bottom: 0;
  width: 100%;
  padding: 10px 10px;
  text-align: left;
  color: #fff;
  background: rgb(15, 3, 43);
  background: linear-gradient(
    90deg,
    rgba(15, 3, 43, 1) 20%,
    rgba(15, 3, 43, 0) 100%
  );
}
.img-card {
  border-top-right-radius: 50px;
  border-bottom-left-radius: 20px;
}
.card-home {
  margin: 15px 10px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 20px;
}
.card-header-home {
  background-color: #141101a9;
  color: #fff;
  font-size: 1.5em;
  padding: 5px 20px;
}
.card-header-home-2 {
  background-color: #5e3500;
  color: #fff;
  font-size: 1.5em;
  padding: 5px 20px;
}

.header-sub-1 {
  font-weight: bold;
  font-size: 1.25em;
  background-color: rgba(3, 3, 36, 0.75);
  color: antiquewhite;
  padding: 5px 20px;
  text-align: center;

  border-top-right-radius: 50px;

  margin: 20px 20px 0px 20px;
}

.industry-desc {
  color: #fff;
  padding: 5px 10px;
  background-color: rgba(0, 0, 255, 0.6);

  border-bottom-left-radius: 50px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
}

.industry-link {
  top: 50px;
  color: #000000;
  padding: 10px 20px;
  background-color: rgba(128, 255, 0, 0.8);
  text-decoration: none;
  font-weight: bold;
  border-top-right-radius: 999em;
  border-top-left-radius: 999em;
  border-bottom-right-radius: 999em;
  border-bottom-left-radius: 999em;
  z-index: 100;
  position: relative;
}
.industry-link-container {
  bottom: 0;
  height: 50%;
  position: absolute;
  width: 100%;
  text-align: center;
}

.clients-container:hover {
  background-color: rgba(0, 255, 64, 0.342);
  cursor: pointer;
}
</style>
