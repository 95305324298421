<template>
  <div id="topnav">
    <NavBar></NavBar>
  </div>

  <div class="body-content">
    <router-view :key="$route.fullPath" />
  </div>
  <FooterView></FooterView>
</template>
<script>
import NavBar from "./components/Layout/PublicWeb/NavBar.vue";
import FooterView from "./components/Layout/PublicWeb/FooterView.vue";
export default {
  components: {
    NavBar,
    FooterView,
  },
  setup() {},
  mounted() {
    //this.matchHeight();
  },
  methods: {
    matchHeight() {
      let height = NavBar.navHeight;
      console.log(height);
    },
  },
};
</script>
<style>
#app {
  font-family: "Oswald";
  font-size: 1.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
