import { createStore } from "vuex";

function initialState() {
  return {
    token: null,
    user: null,
  };
}

export default createStore({
  state: {
    initialState,
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    token: (state) => {
      return state.token;
    },
  },
  mutations: {
    user(state, user) {
      state.user = user;
    },
    token(state, token) {
      state.token = token;
    },

    reset(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    user(context, user) {
      context.commit("user", user);
    },
    token(context, token) {
      context.commit("token", token);
    },
  },
  modules: {},
});
