<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        <a href="https://www.facebook.com/udayaindustries" target="_blank">
          <img
            src="@/assets/images/social-icons/fb.png"
            class="img-thumbnail"
            style="border: 0; height: 40px"
          />
        </a>
        <a href="https://www.instagram.com/udayaindustries/" target="_blank">
          <img
            src="@/assets/images/social-icons/inta.png"
            class="img-thumbnail"
            style="border: 0; height: 40px"
          />
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=94777809905&text="
          target="_blank"
        >
          <img
            src="@/assets/images/social-icons/whatsapp.png"
            class="img-thumbnail"
            style="border: 0; height: 40px"
          />
          +94777809905</a
        >
      </div>
      <div class="col-sm-4">
        <div class="text-center">
          <img
            src="@/assets/images/logo/iso.jpg"
            class="img-thumbnail"
            style="border: 0; height: 60px"
          />
        </div>
      </div>
      <div class="col-sm-4">
        <form class="d-flex s-box" @submit.prevent="search">
          <input
            class="form-control search-text"
            type="search"
            placeholder="Search"
            aria-label="Search"
            v-model="keyword"
          />
          <button class="btn search-btn bg-light" type="submit">
            <i class="bi bi-search"></i>
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="bg-white navcontainer">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg bg-white" ref="nav">
        <div class="container-fluid">
          <router-link class="navbar-brand" :to="'/'"
            ><img
              class="img-fluid"
              style="height: 2rem"
              src="@/assets/images/logo/logo_small.png"
            />
            UDAYA INDUSTRES</router-link
          >
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
            <div class="d-flex me-3">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item hover-underline-animation">
                  <router-link class="nav-link" :to="'/'">HOME</router-link>
                </li>
                <li class="nav-item hover-underline-animation">
                  <router-link class="nav-link" :to="'/about'"
                    >ABOUT</router-link
                  >
                </li>
                <li class="nav-item hover-underline-animation">
                  <router-link class="nav-link" :to="'/products'"
                    >PRODUCTS</router-link
                  >
                </li>
                <li class="nav-item hover-underline-animation">
                  <router-link class="nav-link" :to="'/cocomachines'"
                    >COCONUT</router-link
                  >
                </li>

                <li class="nav-item hover-underline-animation">
                  <router-link class="nav-link" :to="'/foundry'"
                    >FOUNDRY</router-link
                  >
                </li>

                <li class="nav-item hover-underline-animation">
                  <router-link class="nav-link" :to="'/clients'"
                    >CLIENTS</router-link
                  >
                </li>
                <li class="nav-item hover-underline-animation">
                  <router-link class="nav-link" :to="'/contact'"
                    >CONTACT</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavigationBar",
  setup() {},
  data() {
    return {
      keyword: "",
      navHeight: 0,
    };
  },
  mounted() {
    //this.getHeight();
  },
  methods: {
    getHeight() {
      this.navHeight = this.$refs.nav.clientHeight;
      console.log(this.navHeight);
    },
    async search() {
      this.$router.push("/products?k=" + this.keyword);
      // this.$router.push({path: '/products', query:{k: this.keyword}})
    },
  },
};
</script>
<style scoped>
.bg-white {
  background-color: #fff;
}
.navcontainer {
  border-bottom: solid thin rgb(226, 226, 226);
}
.s-box {
  padding: 0.5rem;
}
.search-text {
  border-top-left-radius: 999em;
  border-bottom-left-radius: 999em;
  font-weight: bold;
  padding-left: 20px;
}
.search-text:focus {
  text-align: center;
}
.search-btn {
  border-top-right-radius: 999em;
  border-bottom-right-radius: 999em;
  border: 1px solid #ced4da;
}
.navbar,
.navbar-brand {
  padding: 0;
  font-family: "Oswald-Regular";
  font-size: 1.3em;
}
.nav-item {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  transition: background-color 1s ease-out 100ms;
}
.nav-item:hover {
  background-color: #b4b4b43d;
}
.nav-link {
  padding: 1rem;
}
.logo {
  height: 1rem;
  vertical-align: initial;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #0087ca;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
</style>
