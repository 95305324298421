<template>
  <Carousel :autoplay="4000" v-bind="settings" :breakpoints="breakpoints">
    <Slide v-for="(irow, index) in products_popular" :key="index">
      <div class="p-container" @click="moveTo(irow)">
        <div class="p-body">
          <img class="img-fluid" :src="irow.image" />
          <div class="p-name">{{ irow.name }}</div>
        </div>
      </div>
    </Slide>
  </Carousel>
</template>
<script>
import axios from "axios";
import { defineComponent } from "vue";
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "MostPopularProducts",
  components: {
    Carousel,
    Slide,
  },
  setup() {},
  data() {
    return {
      products_popular: [],
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        wrapAround: true,
        pauseAutoplayOnHover: true,
      },
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: "start",
        },
      },
    };
  },
  beforeMount() {
    this.getProductsPopular();
  },
  methods: {
    async getProductsPopular() {
      try {
        const response = await axios.get("get_product_popular_home");
        this.products_popular = response.data;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async moveTo(i) {
      var l = "/product/" + i.id + "/" + i.slug;
      this.$router.push(l);
    },
  },
});
</script>
<style scoped>
.p-container {
  position: relative;
  padding: 2px;
  cursor: pointer;
}
.p-body {
  border: thin solid #e0e0e0;
}
.p-name {
  position: absolute;
  top: 40%;
  width: 90%;
  background-color: #09021be0;
  color: #e0e0e0;
  text-align: left;
  border-top-right-radius: 999em;
  border-bottom-right-radius: 999em;
  margin-left: 0;
  right: 30px;
  left: 0;
  padding: 10px 20px;
}
</style>
