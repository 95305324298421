<template>
  <div class="bg-udaya">
    <div class="container bg-udaya">
      <div class="row">
        <div class="col-lg-4 mt-3 mb-3" style="text-align: justify">
          <h4>Udaya Industries</h4>
          To become a pioneer industry in Sri Lanka in quality, reliability and
          trustworthy in manufacturing of agro based machinery, plantations
          machinery, Irrigation gates and spares parts for mega industries while
          remaining environmentally friendly.
        </div>
        <div class="col-lg-4 mt-3 mb-3 text-center">
          <img
            class="img-fluid"
            src="@/assets/images/home_page/madeinsrilanka.png"
            style="height: 120px"
          />
        </div>
        <div class="col-lg-4 mt-3 mb-3">
          <h4>Award Winning Company</h4>
          <img
            class="img-fluid"
            src="@/assets/images/about_page/awards/all23.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {},
};
</script>

<style scoped>
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  border-bottom: thin solid #adadad;
}
</style>